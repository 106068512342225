.offerings-container {
  display: flex;
  padding: 2rem;
  margin: 2rem;
}

.offerings-content {
  text-align: justify;
  margin: 2rem;
  font-size: large;
  color: #282c34;
}

.offerings-content-items {
  flex-direction: column;
  display: flex;
  width: 60%;
}

.offerings-headings {
  display: flex;
  flex-direction: row;
  text-align: center;
  color: #282c34;
  /* font-size: xx-large; */
  font-size: 2.5rem;
  width: 40%;
}

@media(max-width:800px) {
  .offerings-container {
    flex-direction: column-reverse;
    margin: 0;
    padding: 0;
  }

  .carrercounsellingimg {
    padding: 0;
    margin: 2rem;
  }

  .offeringsListItems {
    width: 100%;
  }
}

.offerings {
  text-align: center;
  color: #282c34;
  font-size: 5rem;
}

.offeringsList {
  text-align: justify;
  color: black;
  margin: 2rem;
  font-size: large;
  color: #282c34;
}

.offeringsListItems {
  text-align: justify;
  color: black;
  /* margin: 2rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  font-size: large;
  color: #282c34;
}

.carrercounsellingimg {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
}
.authentication__header {
    background-color: rgb(162, 216, 213);
    text-align: center;
    margin: 2rem;
  }
  
  .authentication {
    width: 90%;
    max-width: 25rem;
    margin: 7rem auto;
    text-align: center;
    background-color: #f2f2f2;
  }
  
  .authentication form {
    margin-bottom: 1rem;
  }
.connect-container{
  /* background-color: #f2f2f2;    */
  margin: 0rem; 
  padding: 2rem;
}

.connect-heading{
    text-align: center;
    color:#282c34;
    font-size: 5rem;
}

@media(max-width:800px){
  .connect-heading{
    font-size: xx-large;
  }
  .connect-msg{
    font-size: large;
  }
}

.connect-msg{
    text-align: center;
    margin: 2rem;
    font-size: x-large;
    color: #282c34;
}

.join-button{
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container{
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    height: 15rem;
    width: 100%;
    top:0
}

* {
    box-sizing: border-box;
  }
  
  input[type=text], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* .container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
  } */

/*   
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }
  
  /* Clear floats after the columns */
  /* .row::after {
    content: "";
    display: table;
    clear: both;
  } */
  
  /* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other
  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  } */ 
  .query-form {
    list-style: none;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    background: white;
  }
  
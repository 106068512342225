.content-box-intro {
    display: flex;
    width: 100%;
    padding: 2rem;
    margin: 2rem;
}

.intro-content {
    font-size: large;
    text-align: justify;
    color: #282c34;
}

.content-box-offerings{
    display: flex;
    width: 100%;
    margin: 2rem;
    padding: 2rem;
}

.offerings-heading{
    font-size: xx-large;
    text-align: center;
    width: 50%;
    color: #282c34;
}

.offerings-content{
    width: 50%;
    font-size: large;
    text-align: justify;
}

.content-box-journey{
    display: flex;
    width: 100%;
    margin: 2rem;
    padding: 2rem;
}

.img-content{
    align-items: center;
    width: 75%;
    margin-left: 10%;
}

.img1{
    height: auto;
    object-fit: cover;
    max-width: 85%;
    max-height: 70%;
    padding: 2rem;
    align-content: center;
}

.journey-heading{
    font-size: xx-large;
    text-align: center;
    width: 25%;
    color: #282c34;
    margin-top: 10%;
}

.content-box-who-can-join{
    display: flex;
    width: 100%;
    margin: 2rem;
    padding: 2rem;
}

.who-can-join-heading{
    font-size: xx-large;
    text-align: center;
    width: 50%;
    color: #282c34;
    margin-top: 10%;
}

.who-can-join-content{
    width: 50%;
    font-size: large;
    text-align: justify;
}

.who-can-join-list{
    margin: 2rem;
}

@media(max-width:800px){
    .content-box-intro{
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 1rem;
    }
    .intro-content{
        width: 100%;
        font-size: small;
    }
    .content-box-offerings{
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
        padding: 1rem;
    }
    .offerings-heading{
        width: 100%;
        font-size: large;
    }
    .offerings-content{
        width: 100%;
        font-size: small;
    }
    .content-box-journey{
        /* display: none; */
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 1rem;
    }
    .img-content{
        width: 100%;
        margin-left: 0%;
    }
    .img1{
        height: auto;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        padding: 2rem;
        align-content: center;
    }
    .journey-heading{
        width: 100%;
        font-size: large;
        margin: 0;
    }
    .content-box-who-can-join{
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
        padding: 1rem;
    }
    .who-can-join-heading{
        width: 100%;
        font-size: large;
        margin-top: 0%;
    }
    .who-can-join-content{
        width: 100%;
        font-size: small;
    }
}
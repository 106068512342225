.whocanjoin-container {
  display: flex;
  flex-direction: row;
  margin: 2rem;
  /* background-color: #f2f2f2; */
  padding: 2rem;
}

@media(max-width:800px) {
  .whocanjoin-container {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .whocanjoin {
    width: 100%;
  }

  .whocanjointitle {
    text-align: center;
  }

}


.whocanjoin {
  /* flex-wrap: wrap; */
  text-align: justify;
  /* margin: 2rem; */
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  font-size: large;
  color: #282c34;
  padding: 2rem;
}

.whocanjointitle {
  flex-wrap: wrap;
  text-align: center;
  color: #282c34;
  display: flex;
  font-size: 5rem;
  margin-top: 2rem;
}
.container-who-can-join {
  display: flex;
  width: 100%;
  padding: 2rem;
}

.who-can-join-heading {
  text-align: center;
  color: #282c34;
  font-size: xx-large;
  width: 50%;
}

.who-can-join-content {
  text-align: justify;
  margin: 2rem;
  font-size: large;
  color: #282c34;
  width: 50%;
}
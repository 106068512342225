.intro-container{
    display: flex;
    /* background-color: #f2f2f2; */
    /* padding: 2rem; */
    /* margin: 2rem; */
  }
  
.intro{
  font-size: x-large;
  text-align: justify;
  color: #282c34;
  /* margin: 2rem; */
  /* padding: 2rem; */
}

@media(max-width:800px) {
  .intro-container{
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .intro{
    width: 100%;
    align-items: center;
  }
  
}
.content-box-mission{
    display: flex;
    width: 100%;
    padding: 2rem;
    margin-top: 10%;
}

.aboutUs-mission-headings{
    text-align: center;
    color:#282c34;
    font-size: xx-large;
    width: 50%;
}

.aboutUs-mission-content{
    text-align: justify;
    margin: 2rem;
    font-size: large;
    color: #282c34;
    width: 50%;
}

.content-box-vision{
    display: flex;
    width: 100%;
    padding: 2rem;
    margin-top: 10%;
}

.aboutUs-vision-headings{
    text-align: center;
    color:#282c34;
    font-size: xx-large;
    width: 50%;
}

.aboutUs-vision-content{
    text-align: justify;
    margin: 2rem;
    font-size: large;
    color: #282c34;
    width: 50%;
}

.content-box-values{
    width: 100%;
    padding: 2rem;
    display: flex;
    margin-top: 10%;
}

.aboutUs-values-headings{
    text-align: center;
    color:#282c34;
    font-size: x-large;
    width: 50%;
}

.aboutUs-values-content{
    text-align: justify;
    margin: 1rem;
    font-size: large;
    color: #282c34;
    width: 50%;
}

@media(max-width:800px){
    .content-box-mission{
        flex-direction: column;
        align-items: center;
    }
    .content-box-vision{
        flex-direction: column-reverse;
        align-items: center;
    }
    .content-box-values{
        flex-direction: column;
        align-items: center;
    }
    .container-aboutus{
        flex-direction: column;
        
    }
    .aboutUs-mission-headings{
        width: 100%;
    }
    .aboutUs-vision-headings{
        width: 100%;
    }
    .aboutUs-values-headings{
        width: 100%;
    }
    .aboutUs-mission-content{
        width: 100%;
    }
    .aboutUs-values-content{
        width: 100%;
    }
    .aboutUs-vision-content{
        width: 100%;
    }
}